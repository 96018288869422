<template>
  <div>
    <van-nav-bar
      title="历史报告"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goback"
    />
    <div class="history-report-page">
      <!-- 历史报告列表 -->
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <van-cell
          v-for="report in reportList"
          :key="report.ID"
          :title="`报告 ID: ${report.ID}`"
          :label="`完成时间: ${report.addTime}`"
          @click="goreport(report.ID)"
        />
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reportList: [], // 报告列表数据
      loading: false, // 是否正在加载
      finished: false, // 是否加载完成
    };
  },
  methods: {
    async fetchQuestions() {
      const userId = localStorage.getItem("userId");
      try {
        const response = await fetch(
          `https://assess.eapchina.net/myHistoryReport?userId=${userId}`

          // `http://192.168.1.217:8081/assess/myHistoryReport?userId=${userId}`
        );
        const data = await response.json();
        this.reportList = data;
        this.loading = true;
        this.finished = true;
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    },
    goback() {
      this.$router.go(-1);
    },
    goreport(e) {
      console.log(e);
      const voteLogId = e;
      // 把voteLogId传给Report页面
      this.$router.push({
        path: "/CP/huawei/Report",
        query: { voteLogId },
      });
    },
  },
  created() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.history-report-page {
  padding: 16px;
  background-color: #f7f8fa;
  min-height: 100vh;
}

.van-cell {
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.van-cell__title {
  font-size: 16px;
  font-weight: bold;
}

.van-cell__label {
  font-size: 14px;
  color: #666;
}
</style>
